/** REACT PHONE INPUT */
.react-phone-number-input {
  position: relative;
}
.react-phone-number-input__row{
  display: flex;
  flex-direction: row;
}
.react-phone-number-input__country {
  width: 32px;
}
.react-phone-number-input__icon {
  width: 24px;
  z-index: 0;
  position: relative;
  outline: none;
}
.react-phone-number-input__country-select option {
  color: #333333;
}
.react-phone-number-input__country-select {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  border: none;
  z-index: 1;
  text-indent: -9999999px;
  overflow: hidden;
  height: 100%;
  outline: none;
  background: transparent;
  color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.react-phone-number-input__country-select::-ms-expand {
  display: none;
}
.react-phone-number-input__country:after {
  content: '';
  display: block;
  width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
  position: absolute;
  left: 44px;
  top: 50%;
  margin-top: -2px;
}
.react-phone-number-input__input {
  width: calc(100% - 56px);
  height: 100%;
  border: none;
  position: absolute;
  left: 56px;
  top: 0;
  outline: none;
}
